import React, { useContext, useEffect, useState } from "react";
import { auth, provider } from "../../libs/auth/firebase";
import { Button, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { signInWithRedirect, signOut } from "firebase/auth";
import SwipeableEdgeDrawer from "../drawer/SwipeableEdgeDrawer";
import CustomAppBar from "../appBar/CustomAppBar";
import {
  BookMarkQuestContext,
  BookmarkQuestProvider,
} from "./BookmarkQuestContext";
import {
  MyTracksAndCategoriesContext,
  MyTracksAndCategoriesProvider,
} from "./MyTracksAndCategoriesContext";
import {
  getAndSetCategories,
  setTracksWithCategory,
} from "../../pages/home/homeServices";

const Layout = (props) => {
  const [error, setError] = useState("");
  const [user, setUser] = useState();
  const [tracks, setTracks] = useState([]);
  const [everyCategories, setEveryCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // @ts-ignore
  const { updateMyTracks, updateMyCategories } = useContext(
    MyTracksAndCategoriesContext,
  );

  useEffect(() => {
    getAndSetCategories(setEveryCategories);
  }, []);

  useEffect(() => {
    if (everyCategories.length !== 0 && user)
      setTracksWithCategory(
        user,
        everyCategories,
        setTracks,
        setLoaded,
        setCategories,
      );
  }, [everyCategories]);

  useEffect(() => {
    if (loaded && tracks !== undefined && categories !== undefined) {
      updateMyTracks(tracks);
      updateMyCategories(categories);
    }
  }, [loaded, tracks, categories]);

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user === null || user === undefined) {
        setError("NOTLOGGEDIN");
      } else {
        if (!user.email.includes("sparkmate.com")) {
          setError("NOTSPARKMATEACCOUNT");
        } else {
          setUser(user);
        }
      }
      setLoaded(true);
    });
  }, []);

  return (
    <>
      <BookmarkQuestProvider>
        <SwipeableEdgeDrawer user={user} />
        <CustomAppBar />

        <Modal
          open={error !== ""}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Error
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {error === "NOTSPARKMATEACCOUNT" && (
                <>
                  <p>You did not connect with a Sparkmate.com email account.</p>
                  <button onClick={() => signOut(auth)}> log out</button>
                </>
              )}
              {error === "NOTLOGGEDIN" && (
                <>
                  <p>You need to connect with your google Sparkmate account.</p>
                  <Button
                    variant={"contained"}
                    onClick={() => signInWithRedirect(auth, provider)}
                    style={{
                      background: "#F1FE06",
                      color: "black",
                      fontWeight: "bold",
                      borderRadius: "1.5em",
                      marginTop: "2em",
                    }}
                  >
                    log in with google
                  </Button>
                </>
              )}
            </Typography>
          </Box>
        </Modal>

        {loaded && React.cloneElement(props.children, { user })}
      </BookmarkQuestProvider>
    </>
  );
};

export default Layout;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1.25em",
  boxShadow: 24,
  p: 4,
};
