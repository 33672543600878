// Create a context
import { createContext, useState } from "react";
import React from "react";

// @ts-ignore
export const MyTracksAndCategoriesContext = createContext();

// Create a context provider
// @ts-ignore
export const MyTracksAndCategoriesProvider = ({ children }) => {
  const [myTracks, setMyTracks] = useState([]);
  const [myCategories, setMyCategories] = useState([]);

  // @ts-ignore
  const updateMyTracks = (newValue) => {
    setMyTracks(newValue);
  };

  // @ts-ignore
  const updateMyCategories = (newValue) => {
    setMyCategories(newValue);
  };

  return (
    <MyTracksAndCategoriesContext.Provider
      value={{ myTracks, updateMyTracks, myCategories, updateMyCategories }}
    >
      {children}
    </MyTracksAndCategoriesContext.Provider>
  );
};
