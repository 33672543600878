import Button from "@mui/material/Button";
import { TaskAlt } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Quest } from "./quest";

export const Quests = ({
  quests,
  openFeedbackModal,
  setOpenFeedbackModal,
  teamTrack,
  status,
  forceDone,
}) => {
  const completeAllQuests = () => {
    setOpenFeedbackModal(true);
  };

  return (
    <>
      <div style={styles.mainContainer}>
        <h2 style={{ marginTop: "0.2em" }}>Quests</h2>
        {quests.map((quest, i) => (
          <Quest
            quest={quest}
            key={i}
            forceDone={forceDone}
            status={status}
            isTrainingSession={
              quest.Type && quest.Type[0] === "📅 Training Session"
            }
          />
        ))}

        {teamTrack.Status === "✅ Completed" ? (
          <Button
            variant="contained"
            style={{ width: "100%", background: "#23272C" }}
          >
            You have completed this track
          </Button>
        ) : quests.length === 0 ||
          quests.filter(
            (item) =>
              item.Type &&
              item.Type[0] === "📅 Training Session" &&
              item.Status === "Done",
          ).length ===
            quests.filter(
              (item) => item.Type && item.Type[0] === "📅 Training Session",
            ).length ? (
          <Button
            variant="contained"
            style={{
              background: "#F1FE06",
              color: "black",
              fontWeight: "bold",
              borderRadius: "1.5em",
              width: "100%",
            }}
            endIcon={<TaskAlt />}
            onClick={() => completeAllQuests()}
          >
            Complete track
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{
              width: "100%",
              marginTop: "1em",
              background: "#23272C",
              color: "white",
            }}
            onClick={() => completeAllQuests()}
            disabled
          >
            You have to complete all training sessions to complete the track
          </Button>
        )}
      </div>
    </>
  );
};

const styles = {
  mainContainer: {
    background: "#23272D",
    borderRadius: "20px",
    padding: "20px",
    marginTop: "1.2em",
    color: "white",
  },
  questsContainer: {
    background: "#23272D",
    margin: "1em",
    borderRadius: "10px",
    padding: "1.5em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
  },
};
