import React, { useState } from "react";
import { CourseChapter } from "./courseChapter";
export const CourseContent = ({ chapters }) => {
  return (
    <>
      <h2>Chapters</h2>
      {chapters
        .filter((item) => item.fields.Type === "📖 Resource")
        .sort((a, b) => a.fields.Order - b.fields.Order)
        .map((chapter) => (
          <CourseChapter chapter={chapter} />
        ))}
    </>
  );
};
