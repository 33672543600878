import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { SearchBar } from "./components/searchBar";
import { getAndSetCategories, setTracksWithCategory } from "./homeServices";
import { TeamtrackStatusChip } from "../../components/chip/TeamtrackStatusChip";
import { useNavigate } from "react-router-dom";
import {
  MyTracksAndCategoriesContext,
  MyTracksAndCategoriesProvider,
} from "../../components/layout/MyTracksAndCategoriesContext";

export const Home = ({ user }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const { myTracks, myCategories } = useContext(MyTracksAndCategoriesContext);

  return (
    <div style={styles.layoutDiv}>
      <SearchBar user={user} setSearch={setSearch} />
      <div style={styles.filterDiv}>
        <div style={styles.contentDiv}>
          {myCategories !== undefined && myTracks !== undefined && (
            <table>
              {myCategories.map((category) => (
                <>
                  <tr>
                    <td>
                      <Typography variant="h5" style={{ marginTop: "1.5em" }}>
                        {category.Name}
                      </Typography>
                    </td>
                  </tr>

                  {myTracks
                    .filter((track) => track.Category.Name === category.Name)
                    .filter(
                      (track) =>
                        search === "" ||
                        track.Name.toLowerCase().includes(search.toLowerCase()),
                    )
                    .sort((course1, course2) => {
                      const categoryComparison = course1.Status.localeCompare(
                        course2.Status,
                      );

                      return categoryComparison === 0
                        ? course1.Name.localeCompare(course2.Name)
                        : -1 * categoryComparison;
                    })
                    .map((track) => (
                      <tr
                        style={{ height: "2em", pointer: "pointer" }}
                        onClick={() =>
                          navigate(
                            "/course/" + track.Track + "?teamTrack=" + track.id,
                          )
                        }
                      >
                        <td style={{ pointer: "cursor" }}> {track.Name} </td>
                        <td>
                          <div
                            style={{ marginLeft: "5em", pointer: "pointer" }}
                          >
                            <TeamtrackStatusChip Status={track.Status} />
                          </div>
                        </td>
                      </tr>
                    ))}
                </>
              ))}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  filterDiv: {
    display: "flex",
    marginLeft: "4em",
    marginRight: "4em",
    justifyContent: "space-between",
  },
  layoutDiv: { width: "90%", marginLeft: "auto", marginRight: "auto" },
  contentDiv: {
    flex: 1,
    width: "70%",
    flexWrap: "wrap",
    padding: "10px",
  },
};
