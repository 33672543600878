import { multipleAttachments } from "./multipleAttachments";
import { Categories } from "./Categories";
import { ContentSections } from "./ContentSections";
import { TeamTracks } from "./TeamTracks";
import { RegistrationForm } from "./RegistrationForm";
export interface Tracks { 
id: string;
  Name: string;
  Shortdescription: string;
  Cover: multipleAttachments;
  Stage:  "Locked" | "Unlocked" | "Completed";
  Category: Categories[];
  Unlocks: Tracks[];
  TracksSections: ContentSections[];
  Status:  "Draft" | "Published";
  TracksTrainees: TeamTracks[];
  TracksRegistrationForm: RegistrationForm[];
  Table: string;
  TracksTrainingSessions: string;
  EveryXweeks: number;
  Mustplannextsession: string;
  DatefromTracksTrainingSessions: string;
}

export const getMappedDataTracks = (records: any[]) => {

         const data = records.map((item) => item.fields) 

         return data.map((item, i) => { return { 

        id: records[i].id,
Name : item["Name"],
Shortdescription : item["Short description"],
Cover : item["Cover"],
Stage : item["Stage"],
Category : item["Category"],
Unlocks : item["Unlocks"],
TracksSections : item["Tracks Sections"],
Status : item["Status"],
TracksTrainees : item["Tracks Trainees"],
TracksRegistrationForm : item["Tracks Registration Form"],
Table : item["Table 7"],
TracksTrainingSessions : item["📅 Tracks Training Sessions"],
EveryXweeks : item["Every X weeks"],
Mustplannextsession : item["Must plan next session"],
DatefromTracksTrainingSessions : item["Date (from 📅 Tracks Training Sessions)"],
}})
  }

