export enum AirtableRelationTable {
Categories = "🟢 Categories",
Tracks = "🟢 Tracks",
TeamTracks = "🟢 Team Tracks",
ContentSections = "🟢 Content Sections",
TracksTrainingSessions = "📅 Tracks Training Sessions",
TeamTasks = "⚠️ Team Tasks",
Quests = "⚠️ Quests",
RegistrationForm = "⚠️ Registration Form",
Teammates = "🚫 Teammates",
Slots = "🚫 Slots",

}
