import { TeamtrackStatusChip } from "../../components/chip/TeamtrackStatusChip";
export const CourseHeader = ({ course, teamTrack }) => {
  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerContainer}>
        <div style={styles.secondContainer}>
          <div style={{ display: "flex", height: "100%" }}>
            <img
              width={150}
              height={150}
              style={{ marginRight: "1em", borderRadius: "2em" }}
              src={
                course.Cover
                  ? course.Cover[0].url
                  : "https://assets-global.website-files.com/6413ad1c24cfc2bb5eef3853/6433c59ea50b9c3a7272e3c0_Collision.svg"
              }
            />
            <div style={{ marginLeft: "1em" }}>
              <p style={styles.titleP}>
                <h1 style={styles.title}> {course.Name}</h1>
              </p>
              <p style={{ marginTop: "0em" }}>{course.Shortdescription}</p>
              <TeamtrackStatusChip Status={teamTrack.Status} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: "2em",
    marginBottom: "1em",
  },
  secondContainer: {
    height: "150px",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "auto",
    marginRight: "auto",
  },
  headerContainer: {
    width: "90%",
    justifyContent: "left",
  },
  titleP: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0",
  },
  title: { margin: "0em", marginRight: "0.4em" },
};
