import { Teammates } from "./Teammates";
import { ContentSections } from "./ContentSections";
import { TeamTracks } from "./TeamTracks";
export interface Quests { 
id: string;
  Questscontent: string;
  Teammate: Teammates[];
  Type:  "Resource" | "Tool" | "Quest" | "TrainingSession";
  Status:  "Todo" | "Inprogress" | "Done";
  Quest: ContentSections[];
  useremail: string;
  TeamTracks: TeamTracks[];
  QuestName: string;
  bookmarked: boolean;
}

export const getMappedDataQuests = (records: any[]) => {

         const data = records.map((item) => item.fields) 

         return data.map((item, i) => { return { 

        id: records[i].id,
Questscontent : item["Quests content"],
Teammate : item["Teammate"],
Type : item["Type"],
Status : item["Status"],
Quest : item["Quest"],
useremail : item["user email"],
TeamTracks : item["Team Tracks"],
QuestName : item["QuestName"],
bookmarked : item["bookmarked"],
}})
  }

