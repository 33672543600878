// Create a context
import { createContext, useState } from "react";
import React from 'react'

// @ts-ignore
export const BookMarkQuestContext = createContext();

// Create a context provider
// @ts-ignore
export const BookmarkQuestProvider = ({ children }) => {
  const [bookmarkQuests, setBookmarkQuests] = useState([]);

  // @ts-ignore
  const updateBookmarkQuests = (newValue) => {
    setBookmarkQuests(newValue);
  };

  return (
    <BookMarkQuestContext.Provider
      value={{ bookmarkQuests, updateBookmarkQuests }}
    >
      {children}
    </BookMarkQuestContext.Provider>
  );
};
