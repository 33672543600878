// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithRedirect} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDN-qlzGBqzCW4MGDe_FM00EF2ydr9s9Ts",
    authDomain: "sparkboarding.firebaseapp.com",
    projectId: "sparkboarding",
    storageBucket: "sparkboarding.appspot.com",
    messagingSenderId: "943888901399",
    appId: "1:943888901399:web:1e053c1392ce8e17fdc352"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const provider = new GoogleAuthProvider();
