import React from "react";
import { Chip } from "@mui/material";

export const TeamtrackStatusChip = ({
  Status,
  simpleMode,
}: {
  Status: string;
  simpleMode?: boolean;
}) => {
  return (
    <Chip
      label={simpleMode ? "" : Status}
      style={{
        color: "white",
        height: "1.5em",
        background:
          Status !== "🔒 Locked"
            ? Status === "✅ Completed"
              ? "#196036"
              : "#311A61"
            : "#23272D",
      }}
    />
  );
};
