import { base } from "../../libs/api/AirtableApi";
import { AirtableRelationTable } from "../../types/utils";
import { getMappedDataCategories } from "../../types/Categories";
import { getMappedDataTeamTracks } from "../../types/TeamTracks";

export function getAndSetCategories(setCategories) {
  base(AirtableRelationTable.Categories)
    .select({})
    .eachPage(
      (records, fetchNextPage) => {
        const mappedData = getMappedDataCategories(records);
        setCategories(mappedData);

        fetchNextPage();
      },
      (err) => {
        if (err) {
          console.error("Error retrieving records:", err);
        }
      },
    );
}

export function setTracksWithCategory(
  user,
  categories,
  setTracks,
  setLoaded,
  setCategories,
) {
  base(AirtableRelationTable.TeamTracks)
    .select({
      filterByFormula: `AND({User email} = "${user.email}")`,
    })
    .eachPage(
      (records, fetchNextPage) => {
        const mappedTracks = getMappedDataTeamTracks(records);

        mappedTracks.map((item) => {
          const category = categories.filter(
            (category) => category.id === item.Category[0],
          )[0];
          item.Category = category;
        });

        setTracks(mappedTracks);
        const categoriesInTracks = mappedTracks.map((item) => item.Category);

        const uniqueCategories = [
          ...new Set(categoriesInTracks.map((item) => item.id)),
        ];

        const uniqueCategoryObjects = uniqueCategories.map((id) =>
          categoriesInTracks.find((item) => item.id === id),
        );

        setCategories(uniqueCategoryObjects);
        console.log(uniqueCategoryObjects);

        fetchNextPage();
        setLoaded(true);
      },
      (err) => {
        if (err) {
          console.error("Error retrieving records:", err);
        }
      },
    );
}
