import { TextField } from "@mui/material";
import React from "react";

export function SearchBar({ user, setSearch }) {
  return (
    <div style={styles.mainDiv}>
      <h1>
        Hello {user && user.displayName}, <br /> What do we learn today ?
      </h1>
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder="Search..."
        style={styles.textField}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}

const styles = {
  mainDiv: {
    margin: "4em",
    marginBottom: "0em",
  },
  textField: {
    backgroundColor: "white",
    width: "70%",
    borderRadius: "12em",
  },
};
