import "./App.css";
import { useEffect, useState } from "react";
import Layout from "./components/layout/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { app } from "./libs/auth/firebase";
import { Unauthorized } from "./pages/401/Unauthorized";
import { OneCourse } from "./pages/course/OneCourse";
import {
  getAndSetCategories,
  setTracksWithCategory,
} from "./pages/home/homeServices";
import { MyTracksAndCategoriesProvider } from "./components/layout/MyTracksAndCategoriesContext";

function App() {
  useEffect(() => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
  }, []);

  return (
    <div className="App">
      <MyTracksAndCategoriesProvider>
        <BrowserRouter>
          <Routes>
            <Route
              index
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/unauthorized"
              element={
                <Layout>
                  <Unauthorized />
                </Layout>
              }
            />
            <Route
              path="/course/:id"
              element={
                <Layout>
                  <OneCourse />
                </Layout>
              }
            />
          </Routes>
        </BrowserRouter>
      </MyTracksAndCategoriesProvider>
    </div>
  );
}

export default App;
