import React, {useEffect, useState} from 'react';
import {signOut} from "firebase/auth";
import {auth} from "../../libs/auth/firebase";
import {LinearProgress} from "@mui/material";

export const Unauthorized = () => {
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(function(user) {
            console.log(user.email)
            if(!user.email.includes("sparkmate.com")){
                setError("NOTSPARKMATEACCOUNT")
            }
            setLoaded(true)
        });

    }, [])

    return (
        <div style={{display: "flex", flexWrap: "wrap", padding: "20px", justifyContent: "space-between"}}>

            {loaded ?
            <>
                {(error === "NOTSPARKMATEACCOUNT")&&
                    <>
                        <p>You did not connect with a Sparkmate.com email account.</p>
                        <button onClick={() => signOut(auth)} > log out </button>
                    </>
                }
            </>
                :
                <LinearProgress />
            }

        </div>
    );
}
