import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import { StyledCheckbox } from "../../../components/styledCheckbox/StyledCheckbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useState } from "react";
import Markdown from "react-markdown";
import { getHtlmFromMarkdown } from "../../../utils/MarkdownToHtml";

export const CourseChapter = ({ chapter }) => {
  const [checked, setChecked] = useState(false);
  const [expanded, setExpanded] = useState(true);

  return (
    <Card
      style={{
        width: "90%",
        margin: "1em",
        marginLeft: "0em",
        marginRight: "0em",
        border: "1px solid black",
        borderRadius: "2em",
        padding: "1em",
      }}
    >
      <CardHeader
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: "pointer" }}
        title={<div style={{ display: "flex" }}>{chapter.fields.Name}</div>}
        action={
          <IconButton aria-label="delete">
            {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        }
      />
      {expanded && (
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {chapter.fields.Notes && (
              <div
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{
                  __html: getHtlmFromMarkdown(chapter.fields.Notes),
                }}
              />
            )}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};
