import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { base } from "../../libs/api/AirtableApi";
import { AirtableRelationTable } from "../../types/utils";
import { getMappedDataQuests } from "../../types/Quests";
import { useContext, useEffect, useState } from "react";
import { Quest } from "../../pages/course/quest/quest";
import { BookMarkQuestContext } from "../layout/BookmarkQuestContext";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "50%",
  backgroundColor: "red",
}));

export default function SwipeableEdgeDrawer(props) {
  const { window, user } = props;
  const [open, setOpen] = React.useState(false);
  const [isHover, setIsHover] = useState(false);

  const { bookmarkQuests } = useContext(BookMarkQuestContext);
  const { updateBookmarkQuests } = useContext(BookMarkQuestContext);

  useEffect(() => {
    if (!user) return;
    base(AirtableRelationTable.Quests)
      .select({
        filterByFormula: `AND(
      {User email} = "${user.email}",
      bookmarked = TRUE(),
      status = "Todo"
    )`,
      })
      .eachPage((records, fetchNextPage) => {
        updateBookmarkQuests(getMappedDataQuests(records));
      });
  }, [user]);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const Puller = styled(Box)(({ theme }) => ({
    width: 6,
    height: 50,
    backgroundColor: open ? "#23272D" : "#F1FE06",
    borderRadius: 3,
    position: "absolute",
    top: 75,
    left: 10,
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: open ? "white" : "#23272D",
    height: "50%",
    cursor: "pointer",
  }));

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            width: `calc(20% - ${drawerBleeding}px)`,
            height: "500px",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            overflow: "visible",
            top: 50,
            zIndex: 50,
            backgroundColor: "white",
            pointerEvents: "auto!important",
          },
        }}
      />
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => setOpen(!open)}
          sx={{
            position: "absolute",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            visibility: "visible",
            zIndex: 20,
            left: 0,
            top: 50,
            height: "200px",
            width: "31px",
            marginTop: "50px",
            marginLeft: "-30px",
            right: -drawerBleeding,
            boxShadow: isHover
              ? "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
              : "none",
          }}
        >
          <Puller />
        </StyledBox>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <h1
            style={{
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            My quests
          </h1>
          {bookmarkQuests.length === 0 && (
            <h3
              style={{
                margin: "3em",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <BookmarkBorderIcon /> <br />
              You have no bookmarked quest
            </h3>
          )}
          <div style={{ padding: "1em" }}>
            {bookmarkQuests.map((quest) => (
              <Quest
                quest={quest}
                isTrainingSession={
                  quest.type && quest.Type[0] === "📅 Training Session"
                }
              />
            ))}
          </div>
        </div>
      </SwipeableDrawer>
    </Root>
  );
}
