import { Teammates } from "./Teammates";
import { Tracks } from "./Tracks";
import { multipleAttachments } from "./multipleAttachments";
import { Quests } from "./Quests";

export interface TeamTracks {
  id: string;
  Name: string;
  Status: "Locked" | "Unlocked" | "Review" | "Completed";
  Rating: number;
  Feedback: string;
  Topicstofurtherexplore: string;
  Teammate: Teammates[];
  Track: Tracks[];
  Useremail: string;
  Cover: multipleAttachments;
  ID: number;
  Shortdescription: string;
  Category: string;
  Unlocks: string;
  quests: Quests[];
}

export const getMappedDataTeamTracks = (records: any[]) => {
  const data = records.map((item) => item.fields);

  return data.map((item, i) => {
    return {
      id: records[i].id,
      Name: item["Name"],
      Status: item["Status"],
      Rating: item["Rating"],
      Feedback: item["Feedback"],
      Topicstofurtherexplore: item["Topics to further explore"],
      Teammate: item["Teammate"],
      Track: item["Track"],
      Useremail: item["User email"],
      Cover: item["Cover"],
      ID: item["ID"],
      Shortdescription: item["Short description"],
      Category: item["Category"],
      Unlocks: item["Unlocks..."],
      quests: item["quests"],
    };
  });
};
