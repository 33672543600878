import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { base } from "../../libs/api/AirtableApi";
import { Quests } from "./quest/quests";
import { CourseHeader } from "./courseHeader";
import { CourseContent } from "./content/courseContent";
import { AirtableRelationTable } from "../../types/utils";
import { getMappedDataQuests } from "../../types/Quests";
import { FeedbackModal } from "./feedbackModal/feedbackModal";
import { getMappedDataTracks } from "../../types/Tracks";
import { getMappedDataTeamTracks } from "../../types/TeamTracks";
import { getMappedDataContentSections } from "../../types/ContentSections";
import { MyTracksAndCategoriesContext } from "../../components/layout/MyTracksAndCategoriesContext";
import { Typography } from "@mui/material";
import { TeamtrackStatusChip } from "../../components/chip/TeamtrackStatusChip";

export const OneCourse = ({ user }) => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamTrackParam = searchParams.get("teamTrack");
  const [course, setCourse] = useState({});
  const [chapters, setChapters] = useState([]);
  const [quests, setQuests] = useState([]);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [teamTrack, setTeamTrack] = useState({});
  const [tools, setTools] = useState([]);
  const [forceDone, setForceDone] = useState(false);

  const [reload, setReload] = useState(false);
  const { myTracks, myCategories } = useContext(MyTracksAndCategoriesContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    base("🟢 Tracks")
      .select({
        filterByFormula: `RECORD_ID() = '${id}'`,
      })
      .eachPage(
        (records, fetchNextPage) => {
          fetchNextPage();
          if (records.length > 0) {
            setCourse(getMappedDataTracks(records)[0]);
            base("🟢 Content Sections")
              .select({
                filterByFormula: `AND(OR(${records[0].fields["Tracks Sections"]
                  .map((id) => `RECORD_ID() = '${id}'`)
                  .join(",")}))`,
              })
              .eachPage((records, fetchNextPage) => {
                records
                  .filter((item) => item.fields.Type === "📖 Resource")
                  .sort((a, b) => a.fields.Order - b.fields.Order);
                setChapters(records);

                setTools(
                  getMappedDataContentSections(
                    records.filter((item) => item.fields.Type === "⚙️ Tool"),
                  ),
                );
              });

            base(AirtableRelationTable.Quests)
              .select({
                filterByFormula: `AND(FIND("${records[0].fields.Name}", {Team Tracks}) > 0,
                 {User email} = "${user.email}")`,
              })
              .eachPage((records, fetchNextPage) => {
                const quests = getMappedDataQuests(records);
                setQuests(quests);
              });
          }
        },
        (err) => {
          if (err) {
            console.error("Error retrieving records:", err);
          }
        },
      );
  }, [reload, id]);

  useEffect(() => {
    base(AirtableRelationTable.TeamTracks)
      .select({
        filterByFormula: `RECORD_ID() = '${teamTrackParam}'`,
      })
      .eachPage((records, fetchNextPage) => {
        const teamTrack = getMappedDataTeamTracks(records)[0];
        setTeamTrack(teamTrack);
      });
  }, [reload]);

  useEffect(() => {
    if (forceDone) {
      setTeamTrack({ ...teamTrack, Status: "✅ Completed" });
    }
  }, [forceDone]);

  return (
    <>
      <FeedbackModal
        id={course.id}
        open={openFeedbackModal}
        setOpen={setOpenFeedbackModal}
        quests={quests}
        setForceDone={setForceDone}
        setReload={setReload}
      />

      <div style={{ display: "flex", height: "92vh" }}>
        <div
          style={{
            width: "20%",
            backgroundColor: "grey",
            paddingTop: "1em",
            paddingLeft: "2em",
            paddingBottom: "2em",
          }}
        >
          {myCategories !== undefined && myTracks !== undefined && (
            <>
              {myCategories.map((category) => (
                <>
                  <Typography variant="h5" style={{ marginTop: "1.5em" }}>
                    {category.Name}
                  </Typography>

                  {myTracks
                    .filter((track) => track.Category.Name === category.Name)

                    .sort((course1, course2) => {
                      const categoryComparison = course1.Status.localeCompare(
                        course2.Status,
                      );

                      return categoryComparison === 0
                        ? course1.Name.localeCompare(course2.Name)
                        : -1 * categoryComparison;
                    })
                    .map((track) => (
                      <div
                        style={{ fontSize: "1.05em" }}
                        onClick={() =>
                          navigate(
                            "/course/" + track.Track + "?teamTrack=" + track.id,
                          )
                        }
                      >
                        {track.Name.replace(/\[[^\]]*\]/g, "")}
                        &nbsp;&nbsp;
                        <TeamtrackStatusChip Status={track.Status} simpleMode />
                      </div>
                    ))}
                </>
              ))}
            </>
          )}
        </div>

        <div style={{ width: "80%", overflow: "scroll" }}>
          <CourseHeader course={course} teamTrack={teamTrack} tools={tools} />
          <div style={styles.mainContainer}>
            <div style={{ width: "70%" }}>
              <CourseContent chapters={chapters} />
            </div>
            <div style={{ width: "30%" }}>
              {quests && (
                <Quests
                  quests={quests}
                  openFeedbackModal={openFeedbackModal}
                  setOpenFeedbackModal={setOpenFeedbackModal}
                  teamTrack={teamTrack}
                  forceDone={forceDone}
                  status={teamTrack.Status}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  mainContainer: {
    display: "flex",
    width: "90%",
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
};
