import { Tracks } from "./Tracks";

export interface Categories {
  id: string;
  Name: string;
  Tracks: Tracks[];
}

export const getMappedDataCategories = (records: any[]) => {
  const data = records.map((item) => item.fields);

  return data.map((item, i) => {
    return {
      id: records[i].id,
      Name: item["Name"],
      Tracks: item["Tracks"],
    };
  });
};
