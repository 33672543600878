import { Quests } from "../../../types/Quests";
import React, { useContext, useEffect, useState } from "react";
import { getHtlmFromMarkdown } from "../../../utils/MarkdownToHtml";
import { AirtableRelationTable } from "../../../types/utils";
import { base } from "../../../libs/api/AirtableApi";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import { truncateString } from "../../../utils/string.utils";
import { BookMarkQuestContext } from "../../../components/layout/BookmarkQuestContext";

interface QuestProps {
  quest: Quests;
  forceDone?: boolean;
  isTrainingSession?: boolean;
  status?: string;
}

export const Quest = ({
  quest,
  forceDone,
  isTrainingSession,
  status,
}: QuestProps) => {
  const [checked, setChecked] = useState<boolean>(quest.Status === "Done");
  const [forceBookmarked, setForceBookmarked] = useState<boolean>();

  // @ts-ignore
  const { bookmarkQuests, updateBookmarkQuests } =
    useContext(BookMarkQuestContext);

  useEffect(() => {
    if (forceDone) setChecked(true);
  }, [forceDone]);

  const finishQuest = (id: string, value: boolean) => {
    // @ts-ignore
    base(AirtableRelationTable.Quests)
      .update([
        {
          id: id,
          fields: {
            Status: !value ? "Done" : "Todo",
          },
        },
      ])
      .then(() => setChecked(!checked));
  };

  const bookmarkQuest = () => {
    // @ts-ignore
    base(AirtableRelationTable.Quests)
      .update([
        {
          id: quest.id,
          fields: {
            bookmarked: !quest.bookmarked,
          },
        },
      ])
      .then(() => {
        if (quest.bookmarked || forceBookmarked) {
          updateBookmarkQuests(
            bookmarkQuests.filter((item: Quests) => item.id !== quest.id),
          );
        } else {
          updateBookmarkQuests([
            ...bookmarkQuests,
            { ...quest, bookmarked: true },
          ]);
        }

        setForceBookmarked(
          forceBookmarked === undefined ? !quest.bookmarked : !forceBookmarked,
        );
      });
  };

  if (!quest) return <> </>;

  return (
    <div style={styles.questsContainer}>
      <div style={{ textAlign: "justify", color: "white" }}>
        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
          <div
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{
              __html: getHtlmFromMarkdown(quest.QuestName),
            }}
          />
        </Typography>
        <div
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: truncateString(
              getHtlmFromMarkdown(quest.Questscontent),
              200,
            ),
          }}
        />

        {isTrainingSession && (
          <Tooltip
            title={
              "This quest is a Training Session ! You'll have to attempt a session. The question will be validated by your trainer himself."
            }
          >
            <IconButton style={{ color: "white" }}>
              <CalendarMonthIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {!checked && status !== "✅ Completed" ? (
          <>
            <Button
              variant={"contained"}
              style={{
                background: "#F1FE06",
                color: "black",
                fontWeight: "bold",
                borderRadius: "1.5em",
                marginTop: "0.5em",
              }}
              onClick={() => {
                if (isTrainingSession) return;
                finishQuest(quest.id, checked);
              }}
            >
              Complete Quest
            </Button>
            <Tooltip
              title={
                (forceBookmarked === undefined && quest.bookmarked) ||
                forceBookmarked
                  ? "Unbookmark this quest"
                  : "Bookmark this quest"
              }
            >
              <IconButton
                style={{ color: "white" }}
                onClick={() => bookmarkQuest()}
              >
                {(forceBookmarked === undefined && quest.bookmarked) ||
                forceBookmarked ? (
                  <BookmarkBorderIcon />
                ) : (
                  <BookmarkAddIcon />
                )}
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Button
            variant={"outlined"}
            style={{
              border: "1px solid white",
              color: "white",
              fontWeight: "bold",
              borderRadius: "1.5em",
              marginTop: "0.5em",
            }}
            onClick={() => {
              if (isTrainingSession) return;
              if (status === "✅ Completed") return;
              finishQuest(quest.id, checked);
            }}
          >
            Uncomplete Quest
          </Button>
        )}
      </div>
    </div>
  );
};

const styles = {
  questsContainer: {
    background: "#2E3238",
    marginBottom: "1em",
    borderRadius: "10px",
    padding: "1.5em",
    alignItems: "center",
    fontSize: "12px",
    width: "100%",
  },
};
