import React from "react";
import {
  Box,
  Button,
  Card,
  Modal,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { base } from "../../../libs/api/AirtableApi";
import { AirtableRelationTable } from "../../../types/utils";
import { useParams, useSearchParams } from "react-router-dom";
import { Quests } from "../../../types/Quests";

interface FeedbackModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setReload: (value: boolean) => void;
  setForceDone: (value: boolean) => void;
  quests: Quests[];
}

export const FeedbackModal = ({
  open,
  setOpen,
  quests,
  setReload,
  setForceDone,
}: FeedbackModalProps) => {
  const [value, setValue] = React.useState<number | null>(null);
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const teamTrackId = searchParams.get("teamTrack");

  const onSubmit = async () => {
    let records: { id?: string; fields?: { Status: string } }[] = [];

    quests.forEach((quest) => {
      records.push({
        id: quest.id,
        fields: {
          Status: "Done",
        },
      });
    });

    if (records.length > 0)
      await base(AirtableRelationTable.Quests).update(records);

    await base(AirtableRelationTable.TeamTracks)
      .update([
        {
          id: teamTrackId,
          fields: {
            Rating: value,
            Feedback: feedbackMessage,
            Status: "✅ Completed",
          },
        },
      ])
      .then(() => {
        setForceDone(true);
        setOpen(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Share with us your feedback about this track !
        </Typography>
        <div
          style={{
            margin: "1em",
            marginTop: "2em",
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          <TextField
            id="outlined-textarea"
            label="Feedback"
            placeholder="Feedback"
            multiline
            style={{ width: "100%", marginTop: "2em" }}
            value={feedbackMessage}
            onChange={(event) => setFeedbackMessage(event.target.value)}
            required
          />

          {value === null || feedbackMessage === "" ? (
            <Button
              variant={"outlined"}
              style={{
                border: "1px solid #2E3238",
                color: "#2E3238",
                fontWeight: "bold",
                borderRadius: "1.5em",
                marginTop: "0.5em",
              }}
            >
              Fill the fields first
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{
                background: "#F1FE06",
                color: "black",
                fontWeight: "bold",
                borderRadius: "1.5em",
                marginTop: "2em",
              }}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
          )}
        </div>
      </Card>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
