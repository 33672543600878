import { Tracks } from "./Tracks";
import { Categories } from "./Categories";
import { multipleAttachments } from "./multipleAttachments";
import { Quests } from "./Quests";
export interface ContentSections { 
id: string;
  Name: string;
  Notes: string;
  Type:  "Resource" | "Tool" | "Quest" | "TrainingSession";
  Track: Tracks[];
  CategoryfromTrack: Categories[];
  Order: number;
  Video: multipleAttachments;
  Images: multipleAttachments;
  Files: multipleAttachments;
  Table: Quests[];
  TracksTrainingSessions: string;
}

export const getMappedDataContentSections = (records: any[]) => {

         const data = records.map((item) => item.fields) 

         return data.map((item, i) => { return { 

        id: records[i].id,
Name : item["Name"],
Notes : item["Notes"],
Type : item["Type"],
Track : item["Track"],
CategoryfromTrack : item["Category (from Track)"],
Order : item["Order"],
Video : item["Video"],
Images : item["Images"],
Files : item["Files"],
Table : item["Table 7 (2)"],
TracksTrainingSessions : item["📅 Tracks Training Sessions"],
}})
  }

