import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../../assets/img/logo.png";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../../libs/auth/firebase";
import { Link, useNavigate } from "react-router-dom";

export default function CustomAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [userNameFirstLetter, setUserNameFirstLetter] = useState("X");
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      setUserNameFirstLetter(user.displayName.charAt(0).toUpperCase());
      console.log(user);
    });
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={styles.appBar}>
        <Toolbar style={styles.toolBar}>
          <img src={Logo} width={"300px"} onClick={() => navigate("/")} />

          <Avatar sx={{ bgcolor: "orange" }} onClick={handleClick}>
            {userNameFirstLetter}
          </Avatar>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            style={{ marginLeft: "-3em" }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleClose} onClick={() => signOut(auth)}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
const styles = {
  appBar: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "8vh",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
